<template>
  <div class="preservationBox" :style="{ width: addPage ? 'calc(100vw - 150px)' : 'calc(100vw - 285px)', right: addPage ? '0px' : '15px' }">
    <slot name="return"></slot>
    <template v-if="activeName != null">
      <el-button @click="$emit('nextOr')">{{ activeName == 'first' ? '下一步' : '上一步' }}</el-button>
      <el-button v-show="activeName == 'second'" type="primary" @click="$emit('preservation')">保存</el-button>
    </template>
    <template v-if="isSave != null">
      <el-button type="primary" @click="$emit('preservation')">{{ isSave ? '编辑' : '保存' }}</el-button>
    </template>
    <template v-if="isSave == null && activeName == null">
      <el-button type="primary" @click="$emit('preservation')">{{text}}</el-button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    activeName: {
      type: String,
      default: () => null
    },
    isSave: {
      type: Boolean,
      default: () => null
    },
    addPage: {
      type: Number,
      default: 0
    },
    text:{
      type: String,
      default: '保存'
    }
  }
}
</script>

<style lang="less" scoped>
.preservationBox {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 0 8px 2px rgba(64, 158, 255, 0.2);
}
</style>
